import { AuthAction, withAuthUser } from "@abridge/next-firebase-auth";
import OTPInput from "@components/OTPInput";
import PhoneCountrySelect from "@components/PhoneCountrySelect";
import PhoneInput from "@components/PhoneInput";
import useTimer from "@hooks/useTimer";
import AbridgeLogo from "@public/logos/rebrand/main.svg?svgr";
import LoginGraphic from "@public/graphics/rebrand/waveform.svg?svgr";
import {
  signInWithPHNumber,
  twilioCallCodeSignIn,
  twilioConfirmCallCode,
  twilioSendCallCode,
} from "@services/auth";
import {
  formatPhoneNumberForDisplay,
  getPhNumberErrorMessage,
  getVerificationErrorMessage,
  isValidEmail,
} from "@utils/common";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import React, {
  FormEvent,
  FormEventHandler,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import { Country, isPossiblePhoneNumber } from "react-phone-number-input";
import { LoginInputBubble } from "@components/LoginInputBubble";
import { Input } from "@components/rebrand/Input";
import { logAmplitude } from "@integrations/amplitude";
import { sentryError, SENTRY_TAGS } from "@integrations/sentry";
import { getIdpOrg } from "@services/functions";
import { session } from "@utils/storage";
import router from "next/router";
import { Button } from "@components/rebrand/Button";
import styles from "./Login.module.scss";
import { openBrazeSessionAndSendLoginEvent } from "@integrations/braze";
import { Icon } from "@components/rebrand/Icon";
import { Flex } from "@components/rebrand/Flex";
import { clsx } from "clsx";
import InfoTooltip from "@components/InfoTooltip";
import { getServerSideEnvProps } from "@contexts/environment/getServerSideEnvProps";
import { useEnvironmentVariables } from "@contexts/environment";

const OTP_LENGTH = 6;

const BackButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className={styles["back-button"]} onClick={onClick}>
      <Icon type="arrow-left" />
    </div>
  );
};

const LoginPage = (): ReactElement => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showLoginWithPhoneNumberUI, setShowLoginWithPhoneNumberUI] =
    useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [errors, setErrors] = useState<{
    phoneNumber?: string | null;
    verificationCode?: string | null;
    resendError?: string | null;
    email?: string | null;
  }>({});
  const [phCountry, setPhCountry] = useState<Country>("US");
  const [resendInProgress, setResendInProgress] = useState(false);
  const [callCodeVerification, setCallCodeVerification] = useState(false);
  const [resendTimer, startResendTimer] = useTimer(() =>
    setResendInProgress(false),
  );
  const [formSubmissionInProgress, setFormSubmissionInProgress] =
    useState(false);

  const confirmationResult = useRef<ConfirmationResult | null>(null);
  const recaptchaVerifier = useRef<RecaptchaVerifier | null>(null);
  const [loginEmail, setLoginEmail] = useState<string>("");
  const environmentVariables = useEnvironmentVariables();
  useEffect(() => {
    // Initialize recaptcha on first render
    try {
      setRecaptchaVerifier({ appVerificationDisabledForTesting: false });
      setTimeout(() => {
        window?.document
          ?.querySelector?.(`#abridge-sign-in`)
          ?.scrollIntoView?.({
            behavior: "instant" as any,
          });
      });
    } catch (err) {
      sentryError(err);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        openBrazeSessionAndSendLoginEvent(user.uid);
      }
    });

    return unsubscribe;
  }, []);

  const setRecaptchaVerifier = ({
    appVerificationDisabledForTesting = false,
  }: {
    appVerificationDisabledForTesting?: boolean;
  }) => {
    // https://www.fullstackfirebase.com/firebase-authentication/notes
    if (recaptchaVerifier.current) {
      recaptchaVerifier.current.clear();
    }
    const auth = getAuth();
    auth.settings.appVerificationDisabledForTesting =
      appVerificationDisabledForTesting;
    recaptchaVerifier.current = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
      auth,
    );
  };

  const onTelephoneChange = (newPhoneNumber?: string) => {
    // Disable captcha for particular test phone numbers to allow for automated e2e testing
    const testNumbers = (
      environmentVariables.NEXT_PUBLIC_TEST_NUMBERS || ""
    ).split(",");

    if (testNumbers?.includes(newPhoneNumber || "")) {
      setRecaptchaVerifier({ appVerificationDisabledForTesting: true });
    }

    setPhoneNumber(newPhoneNumber || "");
    setErrors((e) => ({ ...e, phoneNumber: null }));
  };

  const onCountryCodeChange = (newCountryCode?: Country) => {
    if (!newCountryCode) return;
    setPhCountry(newCountryCode);
    setErrors((e) => ({ ...e, phoneNumber: null }));
    setPhoneNumber("");
  };

  const onEmailChange = (newEmail = "") => {
    setLoginEmail(newEmail);
    setErrors((e) => ({ ...e, email: null }));
  };

  const onVerificationCodeChange = (newValue = "") => {
    setVerificationCode(newValue);
    if (errors?.verificationCode) {
      setErrors((e) => ({ ...e, verificationCode: null }));
    }
  };

  const onSubmitVerificationCode: FormEventHandler<HTMLFormElement> = (
    event,
  ) => {
    event?.preventDefault();
    logAmplitude("SOAP_DASHBOARD_LOGIN_PHONE_OTP_ENTERED");
    verify();
  };

  const goBackToPage = (toPage: "phone-input" | "email-input") => {
    if (toPage === "phone-input") {
      setShowLoginWithPhoneNumberUI(true);
    } else {
      setShowLoginWithPhoneNumberUI(false);
    }
    setVerificationCodeSent(false);
    setPhCountry("US");
    setVerificationCode("");
    setPhoneNumber("");
    setErrors({});
    setResendInProgress(false);
    setShowResendButton(false);
  };

  const sendOtpViaCall = async () => {
    try {
      await recaptchaVerifier.current?.verify();
      await twilioSendCallCode(phoneNumber);
    } catch (error) {
      setResendInProgress(false);
      setCallCodeVerification(false);
      let resendError = "Unable to call with code. Please try another mode.";
      interface OTPCallError {
        details: {
          code: number;
        };
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isOTPCallError = (x: any): x is OTPCallError => {
        return typeof x.code === "number";
      };
      if (isOTPCallError(error) && error?.details?.code === 20429) {
        // Rate limit reached
        resendError = "Too many attempts. Please try again later.";
      }
      setErrors((e) => ({
        ...e,
        resendError,
      }));
      sentryError(error, {
        extra: {
          context: resendError,
        },
        tags: {
          flow: SENTRY_TAGS.flow.login,
          authMethod: SENTRY_TAGS.authMethod.phone,
          event: "sendOtpViaCall",
        },
      });
    }
  };

  const resendAuthCode = (type: string): void => {
    startResendTimer(30); // 30 seconds
    setErrors((e) => ({ ...e, resendError: null }));
    switch (type) {
      case "sms":
        setResendInProgress(true);
        setShowResendButton(false);
        setCallCodeVerification(false);
        sendOtpViaSms();
        break;
      case "call":
        setCallCodeVerification(true); // Set verification channel as call.
        setResendInProgress(true);
        setShowResendButton(false);
        // Send code by call.
        sendOtpViaCall();
        break;
      default:
        break;
    }
  };

  const sendOtpViaSms = async () => {
    try {
      setFormSubmissionInProgress(true);
      const result =
        recaptchaVerifier.current &&
        (await signInWithPHNumber(
          phoneNumber || "",
          recaptchaVerifier.current,
        ));
      confirmationResult.current = result;
      setVerificationCodeSent(true);
      setFormSubmissionInProgress(false);
    } catch (error) {
      // error: todo: SMS not sent
      console.log("error", error);
      // sentryError(error);
      setVerificationCodeSent(false);
      setFormSubmissionInProgress(false);
      interface SignInError {
        code: number;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isSignInError = (x: any): x is SignInError => {
        return typeof x.code === "number" || typeof x.code === "string";
      };
      if (isSignInError(error)) {
        setErrors((e) => ({
          ...e,
          phoneNumber: getPhNumberErrorMessage(error as SignInError),
        }));
        sentryError(error, {
          extra: {
            context: "Failed to send SMS code",
          },
          tags: {
            flow: SENTRY_TAGS.flow.login,
            authMethod: SENTRY_TAGS.authMethod.phone,
            event: "sendOtpViaSms",
          },
        });
        logAmplitude("SOAP_DASHBOARD_USER_LOGIN_FAILED", {
          code: error?.code || "",
        });
      }
    }
  };

  const verify = async () => {
    try {
      setFormSubmissionInProgress(true);
      if (callCodeVerification) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { data }: any = await twilioConfirmCallCode(
          phoneNumber,
          verificationCode,
        );
        const { error, success, token } = data;
        if (!success) throw error;

        logAmplitude("SOAP_DASHBOARD_USER_LOGIN");
        await twilioCallCodeSignIn(token);
        return;
      }
      await confirmationResult?.current?.confirm?.(verificationCode);
      logAmplitude("SOAP_DASHBOARD_USER_LOGIN");
      // sign in success triggers onAuthChange
      setFormSubmissionInProgress(false);
    } catch (error) {
      interface SignInVerifyError {
        code: string;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isSignInError = (x: any): x is SignInVerifyError => {
        return typeof x.code === "string";
      };
      if (error && isSignInError(error)) {
        setErrors((e) => ({
          ...e,
          verificationCode: getVerificationErrorMessage(
            error as SignInVerifyError,
          ),
        }));
        logAmplitude("SOAP_DASHBOARD_USER_LOGIN_FAILED", {
          code: error?.code || "",
        });
        sentryError(error, {
          extra: {
            context: "Failed to verify verification code",
          },
          tags: {
            flow: SENTRY_TAGS.flow.login,
            authMethod: SENTRY_TAGS.authMethod.phone,
            event: "verify",
          },
        });
      }
      setFormSubmissionInProgress(false);
    }
  };

  const termsAndConditions = () => {
    return (
      <div id="terms-and-conditions" className={styles["terms-and-conditions"]}>
        By continuing, you agree to Abridge’s <br />
        <a
          href="https://www.abridge.com/abridge-clinician-terms"
          rel="noopener noreferrer"
          target="_blank"
        >
          Clinician Terms & Conditions
        </a>{" "}
        and our{" "}
        <a
          href="https://www.abridge.com/privacy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
        , and to receive{" "}
        <InfoTooltip
          infoText="To opt out, click unsubscribe in any email."
          variant="primary"
        >
          <span className={styles["marketing-tooltip"]}>
            marketing communications
          </span>
        </InfoTooltip>{" "}
        from us.
      </div>
    );
  };

  const renderEmailInput = () => {
    const btnDisabled = !isValidEmail(loginEmail);

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
      // Do nothing if form is invalid and button is disabled
      // Safeguards situation where form is submitted by hitting "Enter"
      e?.preventDefault();
      if (!btnDisabled) {
        try {
          logAmplitude("SOAP_DASHBOARD_LOGIN_EMAIL_ENTERED");
          setFormSubmissionInProgress(true);
          // Set entered login email in local storage for use later
          session.setItem("LOGIN_EMAIL", loginEmail);
          const org = await getIdpOrg(loginEmail);
          if (org) {
            // Corresponding org with IDP, redirect to SSO page
            router.push(`/login/sso/web/${org}`);
          } else {
            // No corresponding identity provider. Log in with phone number
            setShowLoginWithPhoneNumberUI(true);
          }
        } catch (e) {
          console.error("Error when fetching IDP from email", e);
          sentryError(e, {
            extra: {
              context: "Failed to verify identity provider for email address.",
            },
            tags: {
              flow: SENTRY_TAGS.flow.login,
              authMethod: SENTRY_TAGS.authMethod.phone,
              event: "getIdpOrg",
            },
          });
          setErrors((e) => ({
            ...e,
            email: "Failed to verify identity provider for email address.",
          }));
        }
        setFormSubmissionInProgress(false);
      }
    };

    return (
      <LoginInputBubble>
        {renderLogo()}
        <div className={`${styles["sign-in-input-header"]}`}>
          Enter your email to <br />
          sign in or sign up.
        </div>
        <Form onSubmit={handleSubmit} className={styles["email-input-form"]}>
          <Form.Group controlId="email" className={styles["sign-in-email-div"]}>
            <Input
              id="email" // enables autofill
              type="email" // enables autofill
              autoComplete="off"
              value={loginEmail}
              onChange={(e) => onEmailChange(e?.target?.value)}
              placeholder="Your institutional email"
              maxLength={500}
              disabled={formSubmissionInProgress}
              className={styles["sign-in-email-input"]}
            />
          </Form.Group>
        </Form>
        {errors?.email ? (
          <div className={styles["sign-in-email-input-error"]}>
            {errors?.email}
          </div>
        ) : null}
        <div className={styles["sign-in-continue-btn"]}>
          {termsAndConditions()}

          <Button
            inProgress={formSubmissionInProgress}
            onClick={(e: unknown) => {
              const fixedEvent = e as FormEvent<HTMLFormElement>;
              handleSubmit(fixedEvent);
            }}
            disabled={btnDisabled}
            variant={"solid"}
          >
            {verificationCodeSent
              ? "Go"
              : !formSubmissionInProgress
                ? "CONTINUE"
                : ""}
          </Button>
        </div>
      </LoginInputBubble>
    );
  };

  const renderPhoneNumberInput = () => {
    const btnDisabled =
      formSubmissionInProgress ||
      !phoneNumber ||
      !isPossiblePhoneNumber(phoneNumber || "");

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
      // Do nothing if form is invalid and button is disabled
      // Safeguards situation where form is submitted by hitting "Enter"
      event?.preventDefault();
      if (!btnDisabled) {
        sendOtpViaSms();
        logAmplitude("SOAP_DASHBOARD_LOGIN_PHONE_ENTERED");
      }
    };

    return (
      <LoginInputBubble>
        <BackButton
          // Go back to email input UI
          onClick={() => goBackToPage("email-input")}
        />
        {renderLogo()}
        <div
          className={`${styles["sign-in-input-header"]} ${styles["phone-input"]}`}
        >
          Enter your mobile number to <br />
          sign in or sign up.
        </div>
        <Form onSubmit={handleSubmit}>
          <Form.Group
            controlId="phoneNumber"
            className={styles["sign-in-phone-number-div"]}
          >
            <PhoneCountrySelect
              value={phCountry}
              onChange={onCountryCodeChange}
            />
            <PhoneInput
              country={phCountry}
              value={phoneNumber}
              onChange={onTelephoneChange}
              disabled={formSubmissionInProgress}
            />
          </Form.Group>
        </Form>
        {errors?.phoneNumber ? (
          <div className={styles["sign-in-ph-num-input-error"]}>
            {errors?.phoneNumber}
          </div>
        ) : null}
        <div className={styles["sign-in-continue-btn"]}>
          {termsAndConditions()}

          <Button
            inProgress={formSubmissionInProgress}
            onClick={(e: unknown) => {
              const fixedEvent = e as FormEvent<HTMLFormElement>;
              handleSubmit(fixedEvent);
            }}
            disabled={btnDisabled}
          >
            {!formSubmissionInProgress ? "Continue" : ""}
          </Button>
        </div>
      </LoginInputBubble>
    );
  };

  const renderVerificationCodeInput = () => {
    return (
      <LoginInputBubble
        additionalClassName={styles["verification-code-bubble"]}
      >
        {renderLogo()}
        <BackButton
          // Go back to phone input UI
          onClick={() => goBackToPage("phone-input")}
        />
        <div className={styles["sign-in-input-header"]}>
          Enter your {OTP_LENGTH}-digit
          <br />
          verification code.
        </div>
        <Form onSubmit={onSubmitVerificationCode}>
          <Form.Group controlId="verificationCode">
            <div className={styles["sign-in-verification-input"]}>
              <OTPInput
                value={verificationCode}
                onChange={onVerificationCodeChange}
                hasErrored={!!errors.verificationCode}
                length={OTP_LENGTH}
                disabled={formSubmissionInProgress}
              />
              {errors?.verificationCode ? (
                <span className={styles["sign-in-verification-input-error"]}>
                  {errors?.verificationCode}
                </span>
              ) : null}
            </div>
          </Form.Group>

          <div className={styles["sign-in-input-text"]}>
            Your code was sent to:{" "}
            <span className={styles["bold"]}>
              {formatPhoneNumberForDisplay(phoneNumber)}
            </span>
          </div>

          <div className={styles["sign-in-continue-btn"]}>
            <Button
              inProgress={formSubmissionInProgress}
              disabled={
                formSubmissionInProgress ||
                !verificationCode ||
                (verificationCode || "").length < OTP_LENGTH
              }
              onClick={() => "TODO"} // Not sure why there is no onClick here???
            >
              {!formSubmissionInProgress ? "Continue" : ""}
            </Button>
          </div>
        </Form>
        <div>
          <div className={styles["phone-code-resend-text-message"]}>
            <div className={styles["phone-code-resend-link"]}>
              <Button
                variant="text"
                className={styles["phone-code-resend-link-button"]}
                disabled={resendInProgress}
                onClick={() => setShowResendButton(!showResendButton)}
              >
                {resendInProgress
                  ? `${
                      callCodeVerification
                        ? `Calling ${formatPhoneNumberForDisplay(
                            phoneNumber,
                          )} with code.`
                        : `Code sent.`
                    } Resend in ${resendTimer} seconds...`
                  : `Didn’t receive a code?`}
              </Button>
            </div>
          </div>
          <div
            className={clsx(styles["sign-in-send-another-code-container"], {
              [styles["show"]]: showResendButton,
            })}
          >
            <Flex gap="xs">
              {/* div wrapper here helps with smoother animation for the Collapse component */}
              <Button
                variant="outline"
                onClick={() => resendAuthCode("sms")}
                // additionalClassName={
                //   styles["sign-in-send-me-another-code-button"]
                // }
              >
                Send another code
              </Button>
              <Button
                variant="outline"
                onClick={() => resendAuthCode("call")}
                // additionalClassName={styles["sign-in-call-me-with-code-button"]}
              >
                Call with a code
              </Button>
            </Flex>
          </div>
          {errors?.resendError ? (
            <span className={styles["sign-in-verification-input-error"]}>
              {errors?.resendError}
            </span>
          ) : null}
        </div>
      </LoginInputBubble>
    );
  };

  const renderLogo = () => {
    return (
      <div>
        <AbridgeLogo className={styles["abridge-logo-sign-in"]} />
      </div>
    );
  };

  const renderFooter = () => {
    /*
    TODO:
    Legal requirements vary, but as a best practice and to set expectations for your users, you should inform them that if they use phone sign-in, they might receive an SMS message for verification and standard rates apply.
    */
    return (
      <div className={styles["sign-in-footer"]}>
        Trouble logging in? Have a question?
        <br />
        Send us a note at <a href="mailto:help@abridge.com">help@abridge.com</a>
        .
        <br />
        <br />© {new Date()?.getFullYear?.() || ""} <b>Abridge AI Inc.</b>
      </div>
    );
  };

  const renderInputComponent = (): JSX.Element => {
    if (showLoginWithPhoneNumberUI) {
      if (verificationCodeSent) {
        return renderVerificationCodeInput();
      } else {
        return renderPhoneNumberInput();
      }
    } else {
      return renderEmailInput();
    }
  };

  return (
    <>
      <div id="recaptcha-container" />
      <div className={styles["sign-in-root"]}>
        <div className={styles["sign-in"]}>
          <div id="abridge-sign-in" className={styles["sign-in-bubble"]}>
            {renderInputComponent()}
            <LoginGraphic className={styles["login-graphic"]} />
          </div>
          <div className={styles["sign-in-info-main"]}>
            <div className={styles["title"]}>
              Transform <br />
              Conversations <br />
              into Actionable <br />
              Insights
            </div>
            {renderFooter()}
          </div>
        </div>
      </div>
    </>
  );
};

export const getServerSideProps = getServerSideEnvProps;

LoginPage.getLayout = (page: ReactElement) => {
  return page;
};

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})(LoginPage);
